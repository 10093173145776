import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col md="4">
          <h5 className="font-weight-bolder">{t(`FOOTER.HEAD1`)}</h5>
          <ul className="list-unstyled">
            <li>
              <Link to="/imprint">{t(`IMPRINT.TITLE`)}</Link>
            </li>
            <li>
              <Link to="/privacy">{t(`PRIVACY.TITLE`)}</Link>
            </li>
            <li>
              <Link to="/terms">{t(`TERMS_AND_CONDITIONS.TITLE`)}</Link>
            </li>
          </ul>
        </Col>
        {/* eslint-disable react/jsx-no-literals */}
        <Col md="4">
          <h5 className="font-weight-bolder">{t(`FOOTER.HEAD2`)}</h5>
          <address>
            tickettoaster Support
            <br />
            Tel.: +49 561 350 296 28 - 0<br />
            <a href="mailto:hallo@tickettoaster.de">hallo@tickettoaster.de</a>
          </address>
        </Col>
        {/* <Col md="4" className="text-sm-right"></Col> */}
        <Col md="4" className="align-items-end mt-2 text-right">
          <a href="https://tickettoaster.de" target="_blank" rel="noreferrer noopener">
            <img
              src={require('@ttstr/assets/images/teamtoaster.svg')}
              alt="powered by tickettoaster"
              title="powered by tickettoaster"
              width="175px"
              loading="lazy"
            />
          </a>
        </Col>
        {/* eslint-enable react/jsx-no-literals */}
      </Row>
    </Container>
  );
};

export default React.memo(Footer);
